import { Stack, StackProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../helpers/DropdownMenu'; // Import the reusable DropdownMenu component
import { useSelector, useDispatch } from 'react-redux';
import { updateEduLevel, updateSpecialization, updateSpecialistArea } from '../../../actions';

interface StepTwoProps extends StackProps {
  showInfo?: boolean;
  setDisabledSaveBtnEdu?: (bool: boolean) => void
  alertMessage?: string
}

export const StepTwo = ({
  showInfo = false,
  setDisabledSaveBtnEdu,
  alertMessage,
  ...props
}: StepTwoProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userObj = useSelector((state: any) => state.user);

  const eduLevel = useSelector((state: any) => state.eduLevel || userObj.education);
  const specialization = useSelector((state: any) => state.specialization || userObj.specialization);
  const specialistArea = useSelector((state: any) => state.specialistArea || userObj.speciality);


  // Dropdown options
  const educationLevels = [
    { key: t('rate_my.student_apprentice'), description: t('rate_my.includes_all_persons') },
    { key: t('rate_my.career_starters'), description: t('rate_my.young_university_graduates') },
    { key: t('rate_my.expert'), description: t('rate_my.professionals_who_have_completed') },
    { key: t('rate_my.specialist'), description: t('rate_my.practitioners_who_have_undergone') },
    { key: t('rate_my.manager'), description: t('rate_my.experienced_professionals') },
  ];

  const specializations = [
    { key: t('rate_my.doctor') },
    { key: t('rate_my.nurse') },
    { key: t('rate_my.medical_technologist') },
    { key: t('rate_my.physician_assistant') },
    { key: t('rate_my.medical_technology_and_engineering') },
    { key: t('rate_my.pharmaceutical_industry') },
    { key: t('rate_my.research_and_science') },
    { key: t('rate_my.administration_in_healthcare') },
    { key: t('rate_my.health_education') },
    { key: t('rate_my.different_role_in_healthcare_system') },
  ];

  const specialistAreas = [
    { key: t('rate_my.general_practice') },
    { key: t('rate_my.anesthesia') },
    { key: t('rate_my.anatomy') },
    { key: t('rate_my.occupational_medicine') },
    { key: t('rate_my.ophthalmology') },
    { key: t('rate_my.biochemistry') },
    { key: t('rate_my.general_surgery') },
    { key: t('rate_my.vascular_surgery') },
    { key: t('rate_my.cardiac_surgery') },
    { key: t('rate_my.pediatric_surgery') },
    { key: t('rate_my.trauma_surgery') },
    { key: t('rate_my.plastic_surgery') },
    { key: t('rate_my.thoracic_surgery') },
    { key: t('rate_my.abdominal_surgery') },
    { key: t('rate_my.gynecology') },
    { key: t('rate_my.gynecological_endocrinology') },
    { key: t('rate_my.gynecological_oncology') },
    { key: t('rate_my.special_obstetrics') },
    { key: t('rate_my.otolaryngology') },
    { key: t('rate_my.phoniatrics_pediatric_audiology') },
    { key: t('rate_my.dermatology') },
    { key: t('rate_my.genetics') },
    { key: t('rate_my.hygiene_environmental_medicine') },
    { key: t('rate_my.internal_medicine') },
    { key: t('rate_my.vascular_medicine') },
    { key: t('rate_my.endocrinology_diabetology') },
    { key: t('rate_my.gastroenterology') },
    { key: t('rate_my.hematology') },
    { key: t('rate_my.infectious_diseases') },
    { key: t('rate_my.cardiology') },
    { key: t('rate_my.nephrology') },
    { key: t('rate_my.pulmonology') },
    { key: t('rate_my.rheumatology') },
    { key: t('rate_my.pediatrics') },
    { key: t('rate_my.pediatric_hematology_oncology') },
    { key: t('rate_my.pediatric_cardiology') },
    { key: t('rate_my.neonatology') },
    { key: t('rate_my.pediatric_neurology') },
    { key: t('rate_my.child_adolescent_psychiatry') },
    { key: t('rate_my.lab_medicine') },
    { key: t('rate_my.microbiology_virology') },
    { key: t('rate_my.oral_maxillofacial_surgery') },
    { key: t('rate_my.neurosurgery') },
    { key: t('rate_my.neurology') },
    { key: t('rate_my.nuclear_medicine') },
    { key: t('rate_my.public_health') },
    { key: t('rate_my.Neuropathology') },
    { key: t('rate_my.pathology') },
    { key: t('rate_my.clinical_pharmacology') },
    { key: t('rate_my.pharmacology_toxicology') },
    { key: t('rate_my.physical_medicine_and_rehabilitation') },
    { key: t('rate_my.physiology') },
    { key: t('rate_my.psychiatry') },
    { key: t('rate_my.forensic_psychiatry') },
    { key: t('rate_my.psychosomatic_medicine_and_psychotherapy') },
    { key: t('rate_my.radiology') },
    { key: t('rate_my.pediatric_radiology') },
    { key: t('rate_my.Neuroradiology') },
    { key: t('rate_my.forensic_medicine') },
    { key: t('rate_my.radiation_oncology') },
    { key: t('rate_my.transfusion_medicine') },
    { key: t('rate_my.urology') },
    { key: t('rate_my.dentistry') }
  ];

  return (
    <Stack gap={`${!showInfo && "30px"}`} {...props}>
      <Stack spacing={5} gap={`${!showInfo && "30px"}`}>
        {showInfo && <Text className="user-info-data">{t('rate_my.titel_for_edu_edu')}</Text>}
        <DropdownMenu
          label={t('rate_my.education_level')}
          value={eduLevel}
          options={educationLevels}
          alertMessage={alertMessage}
          onSelect={(e) => {
            dispatch(updateEduLevel(e));
            if (setDisabledSaveBtnEdu !== undefined)
              setDisabledSaveBtnEdu(false);
          }}
        />
        {showInfo && <Text className="user-info-data" padding={"16px 0px 0px 0px"}>{t('rate_my.titel_for_edu_specialist_areas')}</Text>}
        <DropdownMenu
          label={t('rate_my.specialization')}
          value={specialization}
          options={specializations}
          alertMessage={alertMessage}
          onSelect={(e) => {
            dispatch(updateSpecialization(e));
            if (setDisabledSaveBtnEdu !== undefined)
              setDisabledSaveBtnEdu(false);
          }}
        />
        {showInfo && <Text className="user-info-data" padding={"16px 0px 0px 0px"}>{t('rate_my.specialization')}</Text>}
        <DropdownMenu
          label={t('rate_my.specialist_areas')}
          value={specialistArea}
          options={specialistAreas}
          alertMessage={alertMessage}
          onSelect={(e) => {
            dispatch(updateSpecialistArea(e));
            if (setDisabledSaveBtnEdu !== undefined)
              setDisabledSaveBtnEdu(false);
          }}
        />
      </Stack>
      {!showInfo && <Text className='step-two-footer-text'>
        {t('rate_my.please_enter_all_required_information')}
      </Text>}
    </Stack>
  );
};