import {
    Avatar,
    Box,
    HStack,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'

import { SidebarButton } from './SidebarButton'
import { useTranslation } from 'react-i18next';
import { Navigation } from './Navigation';
import { UserDto } from 'my-api-client-package';

interface SidebarProps {
    userObj: UserDto;
}
export const Sidebar: React.FC<SidebarProps> = ({ userObj }) => {
    const { t } = useTranslation()
    return (
        <Stack
            flex="1"
            maxW={{ base: 'full', sm: 'xs' }}
            py={{ base: '6', sm: '8' }}
            px={{ base: '4', sm: '6' }}
            bg="#fff"
            borderRightWidth="1px"
            justifyContent="space-between"
        >
            <Stack spacing="8">
                <Navigation />
            </Stack>
            <Stack spacing="4" divider={<StackDivider />}>
                <Box />
                <Stack spacing="1" color={"#4A5568"} fontSize={"14px"} fontWeight={"600"}>
                    <SidebarButton _hover={{ color: "#2C7A7B" }}><a href="https://medimesh.de/nutzungsbedingungen/" target="_blank" >{t("rate_my.terms_of_use")}</a></SidebarButton>
                    <SidebarButton _hover={{ color: "#2C7A7B" }}><a href="https://medimesh.de/datenschutz/" target="_blank" >{t("rate_my.privacy_policy")}</a></SidebarButton>
                </Stack>
                <HStack spacing="3" justify="space-between">
                    <HStack spacing="3">
                        <Avatar boxSize="10" src={userObj.avatar} />
                        <Box>
                            <Text textStyle="sm" fontWeight="medium">
                                {`${userObj.firstname ? userObj.firstname + " " + userObj.lastname : (userObj.email).split("@")[0]}`}
                            </Text>
                            <Text textStyle="sm" color="fg.muted" _hover={{ color: "#2C7A7B" }}>
                                {`${userObj.email}`}
                            </Text>
                        </Box>
                    </HStack>
                </HStack>
            </Stack>
        </Stack>
    )
}