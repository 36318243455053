import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  FormControl,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';

interface DropdownMenuProps {
  label: string; // Label or placeholder for the dropdown
  value: string | null; // Currently selected value
  options: { key: string; description?: string }[]; // Array of options with optional descriptions
  onSelect: (value: string) => void; // Callback for handling selected option
  alertMessage?: string;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ label, value, options, onSelect, alertMessage }) => {
  return (
    <FormControl className='dropdown-main-block'>
      <Menu>
        <MenuButton
          px={4}
          py={2}
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="1px"
          _hover={{ bg: '#EEF2F6' }}
          _expanded={{ bg: '#EEF2F6' }}
          _focus={{ boxShadow: 'none' }}
          width="100%"
          textAlign="left"
          bg={value !== undefined ? '#EEF2F6' : ''}
          borderColor={alertMessage ? value === "" ? 'red.400' : '' : ''}
        >
          <HStack justifyContent="space-between">
            <Text>{value || label}</Text>
            <ChevronDownIcon />
          </HStack>
        </MenuButton>
        <Stack className='dropdown-sub-block'>
          <MenuList padding="10px" width="100%" className="dropdown-menu-list">
            {options.map((option) => (
              <MenuItem
                key={option.key}
                _hover={{ bg: '#EEF2F6' }}
                className='dropdown-menu-item'
                rounded="5px"
                onClick={() => onSelect(option.key)}
                bg={value === option.key ? '#EEF2F6' : ''}>
                <Stack gap={0} className='drop-down-item-container'>
                  <Text className="menu-item-head">{option.key}</Text>
                  {option.description && <Text className="menu-item-desc">{option.description}</Text>}
                </Stack>
              </MenuItem>
            ))}
          </MenuList>
        </Stack>

      </Menu>
    </FormControl>
  );
};

export default DropdownMenu;
